import { useContext } from '@nuxtjs/composition-api';

import { validateFraction } from '~/diptyqueTheme/helpers/priceFormatter';
import { getPrice } from '~/modules/catalog/product/getters/productGetters';
import type { Product } from '~/modules/catalog/product/types';

export const getProductHoverImage = (product: Product): string | null => {
  if (!product || !product['hover_on_card']) {
    return null;
  }

  return product['hover_on_card'];
};

export const getProductPLPDescription = (product: Product): string | null => {
  if (!product || !product['plp_description']) {
    return '';
  }

  return product['plp_description'];
};

export const getProductCapacity = (product: Product): string | null => {
  if (!product || !product['capacity']) {
    return null;
  }

  const capacity = product['capacity'];
  return capacity[0]?.label ?? null;
};

export const getProductVariantName = (product: Product): string | null => {
  if (!product || !product['variant_name']) {
    return null;
  }

  const variant_name = product['variant_name'];
  return variant_name[0]?.label ?? null;
};

export const getRelatedProductsQnty = (product: Product): number | null => {
  if (!product || !product['revamp_related_products']) {
    return null;
  }

  const relatedProducts = product['revamp_related_products'];
  const qty = relatedProducts.length > 1 ? relatedProducts.length - 1 : 0;

  return qty;
};

const getProductEditorial = (product: Product): object[] | null => {
  if (!product || !product['editorial']) {
    return null;
  }

  return product['editorial'].filter((item) => item.label !== 'false');
};

const getProductCardPrice = (product: Product): string => {
  if (!product || Object.keys(product).length === 0) {
    return 'N/A';
  }

  const {
    app: { i18n }
  } = useContext();
  const regularPrice: string = validateFraction(getPrice(product).regular);
  const isDynamic: boolean = product?.price_type === 'DYNAMIC';
  const bundlePrice = isDynamic ? i18n.t('From {price}', { price: validateFraction(product.custom_set_minimal_price) }) : regularPrice;
  const price = product?.__typename === 'BundleProduct' || isDynamic ? bundlePrice : regularPrice;

  return price as string;
};

const getProductBasePrice = (product: Product): string => {
  if (!product || Object.keys(product).length === 0) {
    return '';
  }

  const {
    app: { i18n }
  } = useContext();
  const basePrice: string = validateFraction(Number(getPrice(product).base ?? 0));
  const isDynamic: boolean = product.price_type === 'DYNAMIC';
  const bundlePrice = isDynamic ? i18n.t('From {price}', { price: validateFraction(product.custom_set_minimal_price_excluded_tax) }) : basePrice;
  const price = product?.__typename === 'BundleProduct' || isDynamic ? bundlePrice : basePrice;

  return price as string;
};

const customProductGetters = {
  getProductCapacity,
  getProductVariantName,
  getProductEditorial,
  getProductHoverImage,
  getProductPLPDescription,
  getRelatedProductsQnty,
  getProductCardPrice,
  getProductBasePrice
};

export default customProductGetters;
