import type { ComputedRef, Ref } from '@nuxtjs/composition-api';
// eslint-disable-next-line no-duplicate-imports
import { computed, readonly, ref, useContext, useRoute } from '@nuxtjs/composition-api';

import { useConfig } from '~/composables';
import { useApi } from '~/composables/useApi';
import type { UseStoreErrors, UseStoreInterface, UseStoreUrlRewrites, LocaleConfig } from '~/composables/useStore/useStore';
import { lsGet, lsRemove, lsSet } from '~/diptyqueTheme/composable/useLocalStorage';
import { Logger } from '~/helpers/logger';
import { useCustomerStore } from '~/modules/customer/stores/customer';
import type { Cart, StoreConfig } from '~/modules/GraphQL/types';
import { useConfigStore } from '~/stores/config';

/**
 * Allows loading and changing currently active store.
 *
 * See the {@link UseStoreInterface} for a list of methods and values available in this composable.
 */
export function useStore(): UseStoreInterface {
  const loading: Ref<boolean> = ref(false);
  const error: Ref<UseStoreErrors> = ref({
    load: null,
    change: null
  });
  const configStore = useConfigStore();
  const route = useRoute();
  const { app } = useContext();
  const { cacheableQuery } = useApi();
  const { config } = useConfig();
  const customerStore = useCustomerStore();
  const urlRewrite: Ref<string> = ref('');
  const GetUrlRewriteForStoreQuery = `
    query ($store_from: String!, $store_to: String!, $current_url: String!) {
      getUrlRewriteForStore(store_from: $store_from, store_to: $store_to, current_url: $current_url)
    }
  `;

  const isZhHkStore: ComputedRef<boolean> = computed<boolean>(() => ['zh_hk', 'en_hk'].includes(config?.value.store_code));
  const isJpStore: ComputedRef<boolean> = computed<boolean>(() => config?.value.store_code === 'ja_jp');
  const isUsCaStore: ComputedRef<boolean> = computed<boolean>(() => ['en_us', 'fr_us'].includes(config?.value.store_code));

  const getNameKanaRules = () => {
    const rules = [];
    const KATAKANA_INDEX = 1;
    const HIRAGANA_INDEX = 2;

    if (config.value.require_kana) {
      rules.push('required');
    }
    if (Number(config.value.kana_type) === KATAKANA_INDEX) {
      rules.push('validate-katakana');
    } else if (Number(config.value.kana_type) === HIRAGANA_INDEX) {
      rules.push('validate-hiragana');
    } else {
      rules.push('validate-kana');
    }

    return rules.join('|');
  };

  const getJPDateFormat = () => {
    return isJpStore.value ? 'YYYY-MM-DD' : 'DD/MM/YYYY';
  };
  const createUrl = (givenUrl: string, baseUrl: string = undefined) => {
    Logger.debug('useStoreFactory.createUrl');
    try {
      const url = new URL(givenUrl, baseUrl).pathname;
      return url;
    } catch (err) {
      Logger.error('useStoreFactory.createUrl', err);
      return '';
    }
  };

  const normalizeUrlRewrite = (url: string, slash = '/') => {
    let normalizedUrl = url ?? '';

    // Get the pathname using Magento Base URL for relative URLs (e.g. /en_uk/)
    normalizedUrl = createUrl(normalizedUrl, app.$vsf.$magento.config.magentoBaseUrl);

    // Add trailing slash if absent
    // - window.location.replace concatenates the string if no slash available
    normalizedUrl = normalizedUrl.slice(0, 1) === slash ? normalizedUrl : slash + normalizedUrl;

    return normalizedUrl;
  };

  const getUrlRewriteForStore = async (store_from: string, store_to: string, current_url: string) => {
    Logger.debug('useStoreFactory.getUrlRewriteForStore');
    urlRewrite.value = '';

    try {
      const { data } = await cacheableQuery<UseStoreUrlRewrites>(GetUrlRewriteForStoreQuery, {
        store_from,
        store_to,
        current_url
      });

      urlRewrite.value = normalizeUrlRewrite(data?.getUrlRewriteForStore ?? '');
    } catch (err) {
      Logger.error('useStoreFactory.getUrlRewriteForStore', err);
      urlRewrite.value = '';
    }
  };

  const load = async (customQuery = { availableStores: 'availableStores' }): Promise<void> => {
    Logger.debug('useStoreFactory.load');
    error.value.load = null;

    try {
      loading.value = true;
      const { data } = await app.$vsf.$magento.api.availableStores(customQuery);

      configStore.$patch((state) => {
        state.stores = data?.availableStores ?? [];
      });
    } catch (err) {
      error.value.load = err;
    } finally {
      loading.value = false;
    }
  };

  const change = (store: StoreConfig) => {
    Logger.debug('useStoreFactory.change');

    error.value.change = null;

    try {
      loading.value = true;
      app.$vsf.$magento.config.state.setStore(store.store_code);
      app.$vsf.$magento.config.state.setCurrency(store.default_display_currency_code);
      app.$vsf.$magento.config.state.setLocale(store.store_code);
      const newStoreUrl = app.switchLocalePath(store.store_code);
      window.location.replace(newStoreUrl);
    } catch (err) {
      error.value.change = err;
      Logger.error('[ERROR] useStoreFactory.change', err);
    } finally {
      loading.value = false;
    }
  };

  const changeToUrlRewrite = async (store: StoreConfig, path = '') => {
    Logger.debug('useStoreFactory.changeToUrlRewrite');
    error.value.change = null;

    try {
      loading.value = true;
      const storeFrom = app.$vsf.$magento.config.state.getStore();
      const storeTo = store.store_code;

      // Remove store code from the URL
      const currentUrl = path.replace(/\/([a-zA-Z]{2}_[a-zA-Z]{2})\//, '');

      if (storeFrom !== storeTo) {
        await getUrlRewriteForStore(storeFrom, storeTo, currentUrl);
      }

      // Remove countries && allowed countries from LS when store changed
      lsRemove('allowed_countries');
      lsRemove('all_countries');

      app.$vsf.$magento.config.state.setStore(store.store_code);
      app.$vsf.$magento.config.state.setCurrency(store.default_display_currency_code);
      app.$vsf.$magento.config.state.setLocale(store.store_code);
      const newStoreUrl = app.switchLocalePath(store.store_code);

      window.location.replace(urlRewrite.value || newStoreUrl);
    } catch (err) {
      error.value.change = err;
      Logger.error('[ERROR] useStoreFactory.changeToUrlRewrite', err);
    } finally {
      loading.value = false;
    }
  };

  const setCartInLocalStorageByCode = (cart: Cart): void => {
    const storeCart = lsGet('store_cart');
    const storeCode = configStore.storeConfig.store_code;
    const userType = customerStore.user ? 'user' : 'guest';

    if (storeCart) {
      let storeCartObj = { ...storeCart };
      if (typeof storeCartObj !== 'object') storeCartObj = {};
      if (!storeCartObj[userType]) storeCartObj[userType] = {};
      storeCartObj[userType][storeCode] = cart?.id ?? cart;
      // We reset guest cart_ids to avoid issue about their inactive status
      if (userType === 'user') storeCartObj.guest = {};

      lsSet('store_cart', storeCartObj);
    } else {
      const newStoreCart = {
        user: {},
        guest: {}
      };
      newStoreCart[userType][storeCode] = cart?.id ?? cart;
      lsSet('store_cart', newStoreCart);
    }
  };

  const handleStoreSwitchAndRedirect = async (store: LocaleConfig) => {
    if (app.$vsf.$magento.config.state.getStore() === store.code && checkForAmericanStore(store)) {
      return;
    }

    if (isUsCaStore.value) {
      lsSet('preferredAmericanStore', store.websiteName);
    }

    return changeToUrlRewrite(
      {
        store_code: store.code,
        default_display_currency_code: store.defaultCurrency
      },
      route.value.path
    );
  };

  const checkForAmericanStore = (store: LocaleConfig) => {
    return isUsCaStore.value ? store.websiteName === lsGet('preferredAmericanStore') : true;
  };

  return {
    stores: computed(() => configStore.stores),
    load,
    change,
    getJPDateFormat,
    changeToUrlRewrite,
    setCartInLocalStorageByCode,
    checkForAmericanStore,
    loading: readonly(loading),
    error: readonly(error),
    handleStoreSwitchAndRedirect,
    isZhHkStore,
    isUsCaStore,
    isJpStore,
    getNameKanaRules
  };
}

export default useStore;
export * from './useStore';
