import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _43b40a4c = () => interopDefault(import('../diptyqueTheme/pages/Home.vue' /* webpackChunkName: "" */))
const _08f70eca = () => interopDefault(import('../diptyqueTheme/pages/Cart.vue' /* webpackChunkName: "" */))
const _327fd472 = () => interopDefault(import('../diptyqueTheme/modules/checkout/pages/Checkout.vue' /* webpackChunkName: "" */))
const _23759d8c = () => interopDefault(import('../diptyqueTheme/modules/checkout/pages/Checkout/ErrorPage.vue' /* webpackChunkName: "" */))
const _3f241dc9 = () => interopDefault(import('../diptyqueTheme/modules/checkout/pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _a4423b0a = () => interopDefault(import('../diptyqueTheme/modules/checkout/pages/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _46609729 = () => interopDefault(import('../diptyqueTheme/modules/checkout/pages/Checkout/ShippingAddress.vue' /* webpackChunkName: "" */))
const _d126a3bc = () => interopDefault(import('../diptyqueTheme/modules/checkout/pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _9f4607b6 = () => interopDefault(import('../diptyqueTheme/modules/checkout/pages/Checkout/UserAccount.vue' /* webpackChunkName: "" */))
const _0710fa5c = () => interopDefault(import('../diptyqueTheme/pages/ConfirmSubscription.vue' /* webpackChunkName: "" */))
const _5de44616 = () => interopDefault(import('../diptyqueTheme/pages/Contact.vue' /* webpackChunkName: "" */))
const _7ff18d50 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _5eddd8c6 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _581a9ade = () => interopDefault(import('../modules/customer/pages/MyAccount/MyNewsletter.vue' /* webpackChunkName: "" */))
const _766757c2 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _42fe0774 = () => interopDefault(import('../modules/customer/pages/MyAccount/MyReviews.vue' /* webpackChunkName: "" */))
const _57a2fb97 = () => interopDefault(import('../modules/customer/pages/MyAccount/VaultCardList.vue' /* webpackChunkName: "" */))
const _1e58f5ac = () => interopDefault(import('../modules/customer/pages/MyAccount/MyWishlist.vue' /* webpackChunkName: "" */))
const _106f15f4 = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _7431aa12 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _2208a908 = () => interopDefault(import('../modules/customer/pages/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _648bbe2d = () => interopDefault(import('../modules/customer/pages/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _3918f4fa = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "pages/Home" */))
const _047e46fe = () => interopDefault(import('../diptyqueTheme/pages/Login.vue' /* webpackChunkName: "" */))
const _33a89b33 = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))
const _52e961c2 = () => interopDefault(import('../diptyqueTheme/pages/ResetPassword.vue' /* webpackChunkName: "" */))
const _2e5b108a = () => interopDefault(import('../diptyqueTheme/pages/Page.vue' /* webpackChunkName: "" */))
const _65320980 = () => interopDefault(import('../modules/catalog/pages/category.vue' /* webpackChunkName: "" */))
const _d7e18fc6 = () => interopDefault(import('../modules/catalog/pages/product.vue' /* webpackChunkName: "" */))
const _17a7d42a = () => interopDefault(import('../integrations/klevu-search/pages/KlevuSearch.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/de_eu",
    component: _43b40a4c,
    name: "home___de_eu"
  }, {
    path: "/en_eu",
    component: _43b40a4c,
    name: "home___en_eu"
  }, {
    path: "/en_hk",
    component: _43b40a4c,
    name: "home___en_hk"
  }, {
    path: "/en_uk",
    component: _43b40a4c,
    name: "home___en_uk"
  }, {
    path: "/en_us",
    component: _43b40a4c,
    name: "home___en_us"
  }, {
    path: "/en_us",
    component: _43b40a4c,
    name: "home___en_us"
  }, {
    path: "/es_eu",
    component: _43b40a4c,
    name: "home___es_eu"
  }, {
    path: "/fr_eu",
    component: _43b40a4c,
    name: "home___fr_eu"
  }, {
    path: "/fr_fr",
    component: _43b40a4c,
    name: "home___fr_fr"
  }, {
    path: "/fr_us",
    component: _43b40a4c,
    name: "home___fr_us"
  }, {
    path: "/fr_us",
    component: _43b40a4c,
    name: "home___fr_us"
  }, {
    path: "/it_eu",
    component: _43b40a4c,
    name: "home___it_eu"
  }, {
    path: "/ja_jp",
    component: _43b40a4c,
    name: "home___ja_jp"
  }, {
    path: "/zh_hk",
    component: _43b40a4c,
    name: "home___zh_hk"
  }, {
    path: "/de_eu/cart",
    component: _08f70eca,
    name: "cart___de_eu"
  }, {
    path: "/de_eu/checkout",
    component: _327fd472,
    name: "checkout___de_eu",
    children: [{
      path: "error-page",
      component: _23759d8c,
      name: "error-page___de_eu"
    }, {
      path: "payment",
      component: _3f241dc9,
      name: "payment___de_eu"
    }, {
      path: "shipping",
      component: _a4423b0a,
      name: "shipping___de_eu"
    }, {
      path: "shipping-address",
      component: _46609729,
      name: "shipping-address___de_eu"
    }, {
      path: "thank-you",
      component: _d126a3bc,
      name: "thank-you___de_eu"
    }, {
      path: "user-account",
      component: _9f4607b6,
      name: "user-account___de_eu"
    }]
  }, {
    path: "/de_eu/confirm-subscription",
    component: _0710fa5c,
    name: "confirm-subscription___de_eu"
  }, {
    path: "/de_eu/contact",
    component: _5de44616,
    name: "contact___de_eu"
  }, {
    path: "/de_eu/customer",
    component: _7ff18d50,
    meta: {"titleLabel":"Account | Diptyque Paris"},
    name: "customer___de_eu",
    children: [{
      path: "addresses-details",
      component: _5eddd8c6,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-addresses-details___de_eu"
    }, {
      path: "my-newsletter",
      component: _581a9ade,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-my-newsletter___de_eu"
    }, {
      path: "my-profile",
      component: _766757c2,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-my-profile___de_eu"
    }, {
      path: "my-reviews",
      component: _42fe0774,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-my-reviews___de_eu"
    }, {
      path: "my-vault-card",
      component: _57a2fb97,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-vault-card-list___de_eu"
    }, {
      path: "my-wishlist",
      component: _1e58f5ac,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-my-wishlist___de_eu"
    }, {
      path: "order-history",
      component: _106f15f4,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-order-history___de_eu"
    }, {
      path: "addresses-details/create",
      component: _7431aa12,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-addresses-details-new___de_eu"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _2208a908,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      props: true,
      name: "customer-addresses-details-edit___de_eu"
    }, {
      path: "order-history/:orderId",
      component: _648bbe2d,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      props: true,
      name: "customer-single-order___de_eu"
    }]
  }, {
    path: "/de_eu/Home",
    component: _3918f4fa,
    name: "Home___de_eu"
  }, {
    path: "/de_eu/login",
    component: _047e46fe,
    meta: {"titleLabel":"Login"},
    name: "customer-login___de_eu"
  }, {
    path: "/de_eu/Page",
    component: _33a89b33,
    name: "Page___de_eu"
  }, {
    path: "/de_eu/reset-password",
    component: _52e961c2,
    name: "reset-password___de_eu"
  }, {
    path: "/en_eu/cart",
    component: _08f70eca,
    name: "cart___en_eu"
  }, {
    path: "/en_eu/checkout",
    component: _327fd472,
    name: "checkout___en_eu",
    children: [{
      path: "error-page",
      component: _23759d8c,
      name: "error-page___en_eu"
    }, {
      path: "payment",
      component: _3f241dc9,
      name: "payment___en_eu"
    }, {
      path: "shipping",
      component: _a4423b0a,
      name: "shipping___en_eu"
    }, {
      path: "shipping-address",
      component: _46609729,
      name: "shipping-address___en_eu"
    }, {
      path: "thank-you",
      component: _d126a3bc,
      name: "thank-you___en_eu"
    }, {
      path: "user-account",
      component: _9f4607b6,
      name: "user-account___en_eu"
    }]
  }, {
    path: "/en_eu/confirm-subscription",
    component: _0710fa5c,
    name: "confirm-subscription___en_eu"
  }, {
    path: "/en_eu/contact",
    component: _5de44616,
    name: "contact___en_eu"
  }, {
    path: "/en_eu/customer",
    component: _7ff18d50,
    meta: {"titleLabel":"Account | Diptyque Paris"},
    name: "customer___en_eu",
    children: [{
      path: "addresses-details",
      component: _5eddd8c6,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-addresses-details___en_eu"
    }, {
      path: "my-newsletter",
      component: _581a9ade,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-my-newsletter___en_eu"
    }, {
      path: "my-profile",
      component: _766757c2,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-my-profile___en_eu"
    }, {
      path: "my-reviews",
      component: _42fe0774,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-my-reviews___en_eu"
    }, {
      path: "my-vault-card",
      component: _57a2fb97,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-vault-card-list___en_eu"
    }, {
      path: "my-wishlist",
      component: _1e58f5ac,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-my-wishlist___en_eu"
    }, {
      path: "order-history",
      component: _106f15f4,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-order-history___en_eu"
    }, {
      path: "addresses-details/create",
      component: _7431aa12,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-addresses-details-new___en_eu"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _2208a908,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      props: true,
      name: "customer-addresses-details-edit___en_eu"
    }, {
      path: "order-history/:orderId",
      component: _648bbe2d,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      props: true,
      name: "customer-single-order___en_eu"
    }]
  }, {
    path: "/en_eu/Home",
    component: _3918f4fa,
    name: "Home___en_eu"
  }, {
    path: "/en_eu/login",
    component: _047e46fe,
    meta: {"titleLabel":"Login"},
    name: "customer-login___en_eu"
  }, {
    path: "/en_eu/Page",
    component: _33a89b33,
    name: "Page___en_eu"
  }, {
    path: "/en_eu/reset-password",
    component: _52e961c2,
    name: "reset-password___en_eu"
  }, {
    path: "/en_hk/cart",
    component: _08f70eca,
    name: "cart___en_hk"
  }, {
    path: "/en_hk/checkout",
    component: _327fd472,
    name: "checkout___en_hk",
    children: [{
      path: "error-page",
      component: _23759d8c,
      name: "error-page___en_hk"
    }, {
      path: "payment",
      component: _3f241dc9,
      name: "payment___en_hk"
    }, {
      path: "shipping",
      component: _a4423b0a,
      name: "shipping___en_hk"
    }, {
      path: "shipping-address",
      component: _46609729,
      name: "shipping-address___en_hk"
    }, {
      path: "thank-you",
      component: _d126a3bc,
      name: "thank-you___en_hk"
    }, {
      path: "user-account",
      component: _9f4607b6,
      name: "user-account___en_hk"
    }]
  }, {
    path: "/en_hk/confirm-subscription",
    component: _0710fa5c,
    name: "confirm-subscription___en_hk"
  }, {
    path: "/en_hk/contact",
    component: _5de44616,
    name: "contact___en_hk"
  }, {
    path: "/en_hk/customer",
    component: _7ff18d50,
    meta: {"titleLabel":"Account | Diptyque Paris"},
    name: "customer___en_hk",
    children: [{
      path: "addresses-details",
      component: _5eddd8c6,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-addresses-details___en_hk"
    }, {
      path: "my-newsletter",
      component: _581a9ade,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-my-newsletter___en_hk"
    }, {
      path: "my-profile",
      component: _766757c2,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-my-profile___en_hk"
    }, {
      path: "my-reviews",
      component: _42fe0774,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-my-reviews___en_hk"
    }, {
      path: "my-vault-card",
      component: _57a2fb97,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-vault-card-list___en_hk"
    }, {
      path: "my-wishlist",
      component: _1e58f5ac,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-my-wishlist___en_hk"
    }, {
      path: "order-history",
      component: _106f15f4,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-order-history___en_hk"
    }, {
      path: "addresses-details/create",
      component: _7431aa12,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-addresses-details-new___en_hk"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _2208a908,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      props: true,
      name: "customer-addresses-details-edit___en_hk"
    }, {
      path: "order-history/:orderId",
      component: _648bbe2d,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      props: true,
      name: "customer-single-order___en_hk"
    }]
  }, {
    path: "/en_hk/Home",
    component: _3918f4fa,
    name: "Home___en_hk"
  }, {
    path: "/en_hk/login",
    component: _047e46fe,
    meta: {"titleLabel":"Login"},
    name: "customer-login___en_hk"
  }, {
    path: "/en_hk/Page",
    component: _33a89b33,
    name: "Page___en_hk"
  }, {
    path: "/en_hk/reset-password",
    component: _52e961c2,
    name: "reset-password___en_hk"
  }, {
    path: "/en_uk/cart",
    component: _08f70eca,
    name: "cart___en_uk"
  }, {
    path: "/en_uk/checkout",
    component: _327fd472,
    name: "checkout___en_uk",
    children: [{
      path: "error-page",
      component: _23759d8c,
      name: "error-page___en_uk"
    }, {
      path: "payment",
      component: _3f241dc9,
      name: "payment___en_uk"
    }, {
      path: "shipping",
      component: _a4423b0a,
      name: "shipping___en_uk"
    }, {
      path: "shipping-address",
      component: _46609729,
      name: "shipping-address___en_uk"
    }, {
      path: "thank-you",
      component: _d126a3bc,
      name: "thank-you___en_uk"
    }, {
      path: "user-account",
      component: _9f4607b6,
      name: "user-account___en_uk"
    }]
  }, {
    path: "/en_uk/confirm-subscription",
    component: _0710fa5c,
    name: "confirm-subscription___en_uk"
  }, {
    path: "/en_uk/contact",
    component: _5de44616,
    name: "contact___en_uk"
  }, {
    path: "/en_uk/customer",
    component: _7ff18d50,
    meta: {"titleLabel":"Account | Diptyque Paris"},
    name: "customer___en_uk",
    children: [{
      path: "addresses-details",
      component: _5eddd8c6,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-addresses-details___en_uk"
    }, {
      path: "my-newsletter",
      component: _581a9ade,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-my-newsletter___en_uk"
    }, {
      path: "my-profile",
      component: _766757c2,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-my-profile___en_uk"
    }, {
      path: "my-reviews",
      component: _42fe0774,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-my-reviews___en_uk"
    }, {
      path: "my-vault-card",
      component: _57a2fb97,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-vault-card-list___en_uk"
    }, {
      path: "my-wishlist",
      component: _1e58f5ac,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-my-wishlist___en_uk"
    }, {
      path: "order-history",
      component: _106f15f4,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-order-history___en_uk"
    }, {
      path: "addresses-details/create",
      component: _7431aa12,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-addresses-details-new___en_uk"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _2208a908,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      props: true,
      name: "customer-addresses-details-edit___en_uk"
    }, {
      path: "order-history/:orderId",
      component: _648bbe2d,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      props: true,
      name: "customer-single-order___en_uk"
    }]
  }, {
    path: "/en_uk/Home",
    component: _3918f4fa,
    name: "Home___en_uk"
  }, {
    path: "/en_uk/login",
    component: _047e46fe,
    meta: {"titleLabel":"Login"},
    name: "customer-login___en_uk"
  }, {
    path: "/en_uk/Page",
    component: _33a89b33,
    name: "Page___en_uk"
  }, {
    path: "/en_uk/reset-password",
    component: _52e961c2,
    name: "reset-password___en_uk"
  }, {
    path: "/en_us/cart",
    component: _08f70eca,
    name: "cart___en_us"
  }, {
    path: "/en_us/cart",
    component: _08f70eca,
    name: "cart___en_us"
  }, {
    path: "/en_us/checkout",
    component: _327fd472,
    name: "checkout___en_us",
    children: [{
      path: "error-page",
      component: _23759d8c,
      name: "error-page___en_us"
    }, {
      path: "payment",
      component: _3f241dc9,
      name: "payment___en_us"
    }, {
      path: "shipping",
      component: _a4423b0a,
      name: "shipping___en_us"
    }, {
      path: "shipping-address",
      component: _46609729,
      name: "shipping-address___en_us"
    }, {
      path: "thank-you",
      component: _d126a3bc,
      name: "thank-you___en_us"
    }, {
      path: "user-account",
      component: _9f4607b6,
      name: "user-account___en_us"
    }]
  }, {
    path: "/en_us/checkout",
    component: _327fd472,
    name: "checkout___en_us",
    children: [{
      path: "error-page",
      component: _23759d8c,
      name: "error-page___en_us"
    }, {
      path: "payment",
      component: _3f241dc9,
      name: "payment___en_us"
    }, {
      path: "shipping",
      component: _a4423b0a,
      name: "shipping___en_us"
    }, {
      path: "shipping-address",
      component: _46609729,
      name: "shipping-address___en_us"
    }, {
      path: "thank-you",
      component: _d126a3bc,
      name: "thank-you___en_us"
    }, {
      path: "user-account",
      component: _9f4607b6,
      name: "user-account___en_us"
    }]
  }, {
    path: "/en_us/confirm-subscription",
    component: _0710fa5c,
    name: "confirm-subscription___en_us"
  }, {
    path: "/en_us/confirm-subscription",
    component: _0710fa5c,
    name: "confirm-subscription___en_us"
  }, {
    path: "/en_us/contact",
    component: _5de44616,
    name: "contact___en_us"
  }, {
    path: "/en_us/contact",
    component: _5de44616,
    name: "contact___en_us"
  }, {
    path: "/en_us/customer",
    component: _7ff18d50,
    meta: {"titleLabel":"Account | Diptyque Paris"},
    name: "customer___en_us",
    children: [{
      path: "addresses-details",
      component: _5eddd8c6,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-addresses-details___en_us"
    }, {
      path: "my-newsletter",
      component: _581a9ade,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-my-newsletter___en_us"
    }, {
      path: "my-profile",
      component: _766757c2,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-my-profile___en_us"
    }, {
      path: "my-reviews",
      component: _42fe0774,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-my-reviews___en_us"
    }, {
      path: "my-vault-card",
      component: _57a2fb97,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-vault-card-list___en_us"
    }, {
      path: "my-wishlist",
      component: _1e58f5ac,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-my-wishlist___en_us"
    }, {
      path: "order-history",
      component: _106f15f4,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-order-history___en_us"
    }, {
      path: "addresses-details/create",
      component: _7431aa12,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-addresses-details-new___en_us"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _2208a908,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      props: true,
      name: "customer-addresses-details-edit___en_us"
    }, {
      path: "order-history/:orderId",
      component: _648bbe2d,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      props: true,
      name: "customer-single-order___en_us"
    }]
  }, {
    path: "/en_us/customer",
    component: _7ff18d50,
    meta: {"titleLabel":"Account | Diptyque Paris"},
    name: "customer___en_us",
    children: [{
      path: "addresses-details",
      component: _5eddd8c6,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-addresses-details___en_us"
    }, {
      path: "my-newsletter",
      component: _581a9ade,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-my-newsletter___en_us"
    }, {
      path: "my-profile",
      component: _766757c2,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-my-profile___en_us"
    }, {
      path: "my-reviews",
      component: _42fe0774,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-my-reviews___en_us"
    }, {
      path: "my-vault-card",
      component: _57a2fb97,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-vault-card-list___en_us"
    }, {
      path: "my-wishlist",
      component: _1e58f5ac,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-my-wishlist___en_us"
    }, {
      path: "order-history",
      component: _106f15f4,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-order-history___en_us"
    }, {
      path: "addresses-details/create",
      component: _7431aa12,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-addresses-details-new___en_us"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _2208a908,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      props: true,
      name: "customer-addresses-details-edit___en_us"
    }, {
      path: "order-history/:orderId",
      component: _648bbe2d,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      props: true,
      name: "customer-single-order___en_us"
    }]
  }, {
    path: "/en_us/Home",
    component: _3918f4fa,
    name: "Home___en_us"
  }, {
    path: "/en_us/Home",
    component: _3918f4fa,
    name: "Home___en_us"
  }, {
    path: "/en_us/login",
    component: _047e46fe,
    meta: {"titleLabel":"Login"},
    name: "customer-login___en_us"
  }, {
    path: "/en_us/login",
    component: _047e46fe,
    meta: {"titleLabel":"Login"},
    name: "customer-login___en_us"
  }, {
    path: "/en_us/Page",
    component: _33a89b33,
    name: "Page___en_us"
  }, {
    path: "/en_us/Page",
    component: _33a89b33,
    name: "Page___en_us"
  }, {
    path: "/en_us/reset-password",
    component: _52e961c2,
    name: "reset-password___en_us"
  }, {
    path: "/en_us/reset-password",
    component: _52e961c2,
    name: "reset-password___en_us"
  }, {
    path: "/es_eu/cart",
    component: _08f70eca,
    name: "cart___es_eu"
  }, {
    path: "/es_eu/checkout",
    component: _327fd472,
    name: "checkout___es_eu",
    children: [{
      path: "error-page",
      component: _23759d8c,
      name: "error-page___es_eu"
    }, {
      path: "payment",
      component: _3f241dc9,
      name: "payment___es_eu"
    }, {
      path: "shipping",
      component: _a4423b0a,
      name: "shipping___es_eu"
    }, {
      path: "shipping-address",
      component: _46609729,
      name: "shipping-address___es_eu"
    }, {
      path: "thank-you",
      component: _d126a3bc,
      name: "thank-you___es_eu"
    }, {
      path: "user-account",
      component: _9f4607b6,
      name: "user-account___es_eu"
    }]
  }, {
    path: "/es_eu/confirm-subscription",
    component: _0710fa5c,
    name: "confirm-subscription___es_eu"
  }, {
    path: "/es_eu/contact",
    component: _5de44616,
    name: "contact___es_eu"
  }, {
    path: "/es_eu/customer",
    component: _7ff18d50,
    meta: {"titleLabel":"Account | Diptyque Paris"},
    name: "customer___es_eu",
    children: [{
      path: "addresses-details",
      component: _5eddd8c6,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-addresses-details___es_eu"
    }, {
      path: "my-newsletter",
      component: _581a9ade,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-my-newsletter___es_eu"
    }, {
      path: "my-profile",
      component: _766757c2,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-my-profile___es_eu"
    }, {
      path: "my-reviews",
      component: _42fe0774,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-my-reviews___es_eu"
    }, {
      path: "my-vault-card",
      component: _57a2fb97,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-vault-card-list___es_eu"
    }, {
      path: "my-wishlist",
      component: _1e58f5ac,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-my-wishlist___es_eu"
    }, {
      path: "order-history",
      component: _106f15f4,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-order-history___es_eu"
    }, {
      path: "addresses-details/create",
      component: _7431aa12,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-addresses-details-new___es_eu"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _2208a908,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      props: true,
      name: "customer-addresses-details-edit___es_eu"
    }, {
      path: "order-history/:orderId",
      component: _648bbe2d,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      props: true,
      name: "customer-single-order___es_eu"
    }]
  }, {
    path: "/es_eu/Home",
    component: _3918f4fa,
    name: "Home___es_eu"
  }, {
    path: "/es_eu/login",
    component: _047e46fe,
    meta: {"titleLabel":"Login"},
    name: "customer-login___es_eu"
  }, {
    path: "/es_eu/Page",
    component: _33a89b33,
    name: "Page___es_eu"
  }, {
    path: "/es_eu/reset-password",
    component: _52e961c2,
    name: "reset-password___es_eu"
  }, {
    path: "/fr_eu/cart",
    component: _08f70eca,
    name: "cart___fr_eu"
  }, {
    path: "/fr_eu/checkout",
    component: _327fd472,
    name: "checkout___fr_eu",
    children: [{
      path: "error-page",
      component: _23759d8c,
      name: "error-page___fr_eu"
    }, {
      path: "payment",
      component: _3f241dc9,
      name: "payment___fr_eu"
    }, {
      path: "shipping",
      component: _a4423b0a,
      name: "shipping___fr_eu"
    }, {
      path: "shipping-address",
      component: _46609729,
      name: "shipping-address___fr_eu"
    }, {
      path: "thank-you",
      component: _d126a3bc,
      name: "thank-you___fr_eu"
    }, {
      path: "user-account",
      component: _9f4607b6,
      name: "user-account___fr_eu"
    }]
  }, {
    path: "/fr_eu/confirm-subscription",
    component: _0710fa5c,
    name: "confirm-subscription___fr_eu"
  }, {
    path: "/fr_eu/contact",
    component: _5de44616,
    name: "contact___fr_eu"
  }, {
    path: "/fr_eu/customer",
    component: _7ff18d50,
    meta: {"titleLabel":"Account | Diptyque Paris"},
    name: "customer___fr_eu",
    children: [{
      path: "addresses-details",
      component: _5eddd8c6,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-addresses-details___fr_eu"
    }, {
      path: "my-newsletter",
      component: _581a9ade,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-my-newsletter___fr_eu"
    }, {
      path: "my-profile",
      component: _766757c2,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-my-profile___fr_eu"
    }, {
      path: "my-reviews",
      component: _42fe0774,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-my-reviews___fr_eu"
    }, {
      path: "my-vault-card",
      component: _57a2fb97,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-vault-card-list___fr_eu"
    }, {
      path: "my-wishlist",
      component: _1e58f5ac,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-my-wishlist___fr_eu"
    }, {
      path: "order-history",
      component: _106f15f4,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-order-history___fr_eu"
    }, {
      path: "addresses-details/create",
      component: _7431aa12,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-addresses-details-new___fr_eu"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _2208a908,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      props: true,
      name: "customer-addresses-details-edit___fr_eu"
    }, {
      path: "order-history/:orderId",
      component: _648bbe2d,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      props: true,
      name: "customer-single-order___fr_eu"
    }]
  }, {
    path: "/fr_eu/Home",
    component: _3918f4fa,
    name: "Home___fr_eu"
  }, {
    path: "/fr_eu/login",
    component: _047e46fe,
    meta: {"titleLabel":"Login"},
    name: "customer-login___fr_eu"
  }, {
    path: "/fr_eu/Page",
    component: _33a89b33,
    name: "Page___fr_eu"
  }, {
    path: "/fr_eu/reset-password",
    component: _52e961c2,
    name: "reset-password___fr_eu"
  }, {
    path: "/fr_fr/cart",
    component: _08f70eca,
    name: "cart___fr_fr"
  }, {
    path: "/fr_fr/checkout",
    component: _327fd472,
    name: "checkout___fr_fr",
    children: [{
      path: "error-page",
      component: _23759d8c,
      name: "error-page___fr_fr"
    }, {
      path: "payment",
      component: _3f241dc9,
      name: "payment___fr_fr"
    }, {
      path: "shipping",
      component: _a4423b0a,
      name: "shipping___fr_fr"
    }, {
      path: "shipping-address",
      component: _46609729,
      name: "shipping-address___fr_fr"
    }, {
      path: "thank-you",
      component: _d126a3bc,
      name: "thank-you___fr_fr"
    }, {
      path: "user-account",
      component: _9f4607b6,
      name: "user-account___fr_fr"
    }]
  }, {
    path: "/fr_fr/confirm-subscription",
    component: _0710fa5c,
    name: "confirm-subscription___fr_fr"
  }, {
    path: "/fr_fr/contact",
    component: _5de44616,
    name: "contact___fr_fr"
  }, {
    path: "/fr_fr/customer",
    component: _7ff18d50,
    meta: {"titleLabel":"Account | Diptyque Paris"},
    name: "customer___fr_fr",
    children: [{
      path: "addresses-details",
      component: _5eddd8c6,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-addresses-details___fr_fr"
    }, {
      path: "my-newsletter",
      component: _581a9ade,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-my-newsletter___fr_fr"
    }, {
      path: "my-profile",
      component: _766757c2,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-my-profile___fr_fr"
    }, {
      path: "my-reviews",
      component: _42fe0774,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-my-reviews___fr_fr"
    }, {
      path: "my-vault-card",
      component: _57a2fb97,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-vault-card-list___fr_fr"
    }, {
      path: "my-wishlist",
      component: _1e58f5ac,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-my-wishlist___fr_fr"
    }, {
      path: "order-history",
      component: _106f15f4,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-order-history___fr_fr"
    }, {
      path: "addresses-details/create",
      component: _7431aa12,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-addresses-details-new___fr_fr"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _2208a908,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      props: true,
      name: "customer-addresses-details-edit___fr_fr"
    }, {
      path: "order-history/:orderId",
      component: _648bbe2d,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      props: true,
      name: "customer-single-order___fr_fr"
    }]
  }, {
    path: "/fr_fr/Home",
    component: _3918f4fa,
    name: "Home___fr_fr"
  }, {
    path: "/fr_fr/login",
    component: _047e46fe,
    meta: {"titleLabel":"Login"},
    name: "customer-login___fr_fr"
  }, {
    path: "/fr_fr/Page",
    component: _33a89b33,
    name: "Page___fr_fr"
  }, {
    path: "/fr_fr/reset-password",
    component: _52e961c2,
    name: "reset-password___fr_fr"
  }, {
    path: "/fr_us/cart",
    component: _08f70eca,
    name: "cart___fr_us"
  }, {
    path: "/fr_us/cart",
    component: _08f70eca,
    name: "cart___fr_us"
  }, {
    path: "/fr_us/checkout",
    component: _327fd472,
    name: "checkout___fr_us",
    children: [{
      path: "error-page",
      component: _23759d8c,
      name: "error-page___fr_us"
    }, {
      path: "payment",
      component: _3f241dc9,
      name: "payment___fr_us"
    }, {
      path: "shipping",
      component: _a4423b0a,
      name: "shipping___fr_us"
    }, {
      path: "shipping-address",
      component: _46609729,
      name: "shipping-address___fr_us"
    }, {
      path: "thank-you",
      component: _d126a3bc,
      name: "thank-you___fr_us"
    }, {
      path: "user-account",
      component: _9f4607b6,
      name: "user-account___fr_us"
    }]
  }, {
    path: "/fr_us/checkout",
    component: _327fd472,
    name: "checkout___fr_us",
    children: [{
      path: "error-page",
      component: _23759d8c,
      name: "error-page___fr_us"
    }, {
      path: "payment",
      component: _3f241dc9,
      name: "payment___fr_us"
    }, {
      path: "shipping",
      component: _a4423b0a,
      name: "shipping___fr_us"
    }, {
      path: "shipping-address",
      component: _46609729,
      name: "shipping-address___fr_us"
    }, {
      path: "thank-you",
      component: _d126a3bc,
      name: "thank-you___fr_us"
    }, {
      path: "user-account",
      component: _9f4607b6,
      name: "user-account___fr_us"
    }]
  }, {
    path: "/fr_us/confirm-subscription",
    component: _0710fa5c,
    name: "confirm-subscription___fr_us"
  }, {
    path: "/fr_us/confirm-subscription",
    component: _0710fa5c,
    name: "confirm-subscription___fr_us"
  }, {
    path: "/fr_us/contact",
    component: _5de44616,
    name: "contact___fr_us"
  }, {
    path: "/fr_us/contact",
    component: _5de44616,
    name: "contact___fr_us"
  }, {
    path: "/fr_us/customer",
    component: _7ff18d50,
    meta: {"titleLabel":"Account | Diptyque Paris"},
    name: "customer___fr_us",
    children: [{
      path: "addresses-details",
      component: _5eddd8c6,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-addresses-details___fr_us"
    }, {
      path: "my-newsletter",
      component: _581a9ade,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-my-newsletter___fr_us"
    }, {
      path: "my-profile",
      component: _766757c2,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-my-profile___fr_us"
    }, {
      path: "my-reviews",
      component: _42fe0774,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-my-reviews___fr_us"
    }, {
      path: "my-vault-card",
      component: _57a2fb97,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-vault-card-list___fr_us"
    }, {
      path: "my-wishlist",
      component: _1e58f5ac,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-my-wishlist___fr_us"
    }, {
      path: "order-history",
      component: _106f15f4,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-order-history___fr_us"
    }, {
      path: "addresses-details/create",
      component: _7431aa12,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-addresses-details-new___fr_us"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _2208a908,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      props: true,
      name: "customer-addresses-details-edit___fr_us"
    }, {
      path: "order-history/:orderId",
      component: _648bbe2d,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      props: true,
      name: "customer-single-order___fr_us"
    }]
  }, {
    path: "/fr_us/customer",
    component: _7ff18d50,
    meta: {"titleLabel":"Account | Diptyque Paris"},
    name: "customer___fr_us",
    children: [{
      path: "addresses-details",
      component: _5eddd8c6,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-addresses-details___fr_us"
    }, {
      path: "my-newsletter",
      component: _581a9ade,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-my-newsletter___fr_us"
    }, {
      path: "my-profile",
      component: _766757c2,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-my-profile___fr_us"
    }, {
      path: "my-reviews",
      component: _42fe0774,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-my-reviews___fr_us"
    }, {
      path: "my-vault-card",
      component: _57a2fb97,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-vault-card-list___fr_us"
    }, {
      path: "my-wishlist",
      component: _1e58f5ac,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-my-wishlist___fr_us"
    }, {
      path: "order-history",
      component: _106f15f4,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-order-history___fr_us"
    }, {
      path: "addresses-details/create",
      component: _7431aa12,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-addresses-details-new___fr_us"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _2208a908,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      props: true,
      name: "customer-addresses-details-edit___fr_us"
    }, {
      path: "order-history/:orderId",
      component: _648bbe2d,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      props: true,
      name: "customer-single-order___fr_us"
    }]
  }, {
    path: "/fr_us/Home",
    component: _3918f4fa,
    name: "Home___fr_us"
  }, {
    path: "/fr_us/Home",
    component: _3918f4fa,
    name: "Home___fr_us"
  }, {
    path: "/fr_us/login",
    component: _047e46fe,
    meta: {"titleLabel":"Login"},
    name: "customer-login___fr_us"
  }, {
    path: "/fr_us/login",
    component: _047e46fe,
    meta: {"titleLabel":"Login"},
    name: "customer-login___fr_us"
  }, {
    path: "/fr_us/Page",
    component: _33a89b33,
    name: "Page___fr_us"
  }, {
    path: "/fr_us/Page",
    component: _33a89b33,
    name: "Page___fr_us"
  }, {
    path: "/fr_us/reset-password",
    component: _52e961c2,
    name: "reset-password___fr_us"
  }, {
    path: "/fr_us/reset-password",
    component: _52e961c2,
    name: "reset-password___fr_us"
  }, {
    path: "/it_eu/cart",
    component: _08f70eca,
    name: "cart___it_eu"
  }, {
    path: "/it_eu/checkout",
    component: _327fd472,
    name: "checkout___it_eu",
    children: [{
      path: "error-page",
      component: _23759d8c,
      name: "error-page___it_eu"
    }, {
      path: "payment",
      component: _3f241dc9,
      name: "payment___it_eu"
    }, {
      path: "shipping",
      component: _a4423b0a,
      name: "shipping___it_eu"
    }, {
      path: "shipping-address",
      component: _46609729,
      name: "shipping-address___it_eu"
    }, {
      path: "thank-you",
      component: _d126a3bc,
      name: "thank-you___it_eu"
    }, {
      path: "user-account",
      component: _9f4607b6,
      name: "user-account___it_eu"
    }]
  }, {
    path: "/it_eu/confirm-subscription",
    component: _0710fa5c,
    name: "confirm-subscription___it_eu"
  }, {
    path: "/it_eu/contact",
    component: _5de44616,
    name: "contact___it_eu"
  }, {
    path: "/it_eu/customer",
    component: _7ff18d50,
    meta: {"titleLabel":"Account | Diptyque Paris"},
    name: "customer___it_eu",
    children: [{
      path: "addresses-details",
      component: _5eddd8c6,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-addresses-details___it_eu"
    }, {
      path: "my-newsletter",
      component: _581a9ade,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-my-newsletter___it_eu"
    }, {
      path: "my-profile",
      component: _766757c2,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-my-profile___it_eu"
    }, {
      path: "my-reviews",
      component: _42fe0774,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-my-reviews___it_eu"
    }, {
      path: "my-vault-card",
      component: _57a2fb97,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-vault-card-list___it_eu"
    }, {
      path: "my-wishlist",
      component: _1e58f5ac,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-my-wishlist___it_eu"
    }, {
      path: "order-history",
      component: _106f15f4,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-order-history___it_eu"
    }, {
      path: "addresses-details/create",
      component: _7431aa12,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-addresses-details-new___it_eu"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _2208a908,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      props: true,
      name: "customer-addresses-details-edit___it_eu"
    }, {
      path: "order-history/:orderId",
      component: _648bbe2d,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      props: true,
      name: "customer-single-order___it_eu"
    }]
  }, {
    path: "/it_eu/Home",
    component: _3918f4fa,
    name: "Home___it_eu"
  }, {
    path: "/it_eu/login",
    component: _047e46fe,
    meta: {"titleLabel":"Login"},
    name: "customer-login___it_eu"
  }, {
    path: "/it_eu/Page",
    component: _33a89b33,
    name: "Page___it_eu"
  }, {
    path: "/it_eu/reset-password",
    component: _52e961c2,
    name: "reset-password___it_eu"
  }, {
    path: "/ja_jp/cart",
    component: _08f70eca,
    name: "cart___ja_jp"
  }, {
    path: "/ja_jp/checkout",
    component: _327fd472,
    name: "checkout___ja_jp",
    children: [{
      path: "error-page",
      component: _23759d8c,
      name: "error-page___ja_jp"
    }, {
      path: "payment",
      component: _3f241dc9,
      name: "payment___ja_jp"
    }, {
      path: "shipping",
      component: _a4423b0a,
      name: "shipping___ja_jp"
    }, {
      path: "shipping-address",
      component: _46609729,
      name: "shipping-address___ja_jp"
    }, {
      path: "thank-you",
      component: _d126a3bc,
      name: "thank-you___ja_jp"
    }, {
      path: "user-account",
      component: _9f4607b6,
      name: "user-account___ja_jp"
    }]
  }, {
    path: "/ja_jp/confirm-subscription",
    component: _0710fa5c,
    name: "confirm-subscription___ja_jp"
  }, {
    path: "/ja_jp/contact",
    component: _5de44616,
    name: "contact___ja_jp"
  }, {
    path: "/ja_jp/customer",
    component: _7ff18d50,
    meta: {"titleLabel":"Account | Diptyque Paris"},
    name: "customer___ja_jp",
    children: [{
      path: "addresses-details",
      component: _5eddd8c6,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-addresses-details___ja_jp"
    }, {
      path: "my-newsletter",
      component: _581a9ade,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-my-newsletter___ja_jp"
    }, {
      path: "my-profile",
      component: _766757c2,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-my-profile___ja_jp"
    }, {
      path: "my-reviews",
      component: _42fe0774,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-my-reviews___ja_jp"
    }, {
      path: "my-vault-card",
      component: _57a2fb97,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-vault-card-list___ja_jp"
    }, {
      path: "my-wishlist",
      component: _1e58f5ac,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-my-wishlist___ja_jp"
    }, {
      path: "order-history",
      component: _106f15f4,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-order-history___ja_jp"
    }, {
      path: "addresses-details/create",
      component: _7431aa12,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-addresses-details-new___ja_jp"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _2208a908,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      props: true,
      name: "customer-addresses-details-edit___ja_jp"
    }, {
      path: "order-history/:orderId",
      component: _648bbe2d,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      props: true,
      name: "customer-single-order___ja_jp"
    }]
  }, {
    path: "/ja_jp/Home",
    component: _3918f4fa,
    name: "Home___ja_jp"
  }, {
    path: "/ja_jp/login",
    component: _047e46fe,
    meta: {"titleLabel":"Login"},
    name: "customer-login___ja_jp"
  }, {
    path: "/ja_jp/Page",
    component: _33a89b33,
    name: "Page___ja_jp"
  }, {
    path: "/ja_jp/reset-password",
    component: _52e961c2,
    name: "reset-password___ja_jp"
  }, {
    path: "/zh_hk/cart",
    component: _08f70eca,
    name: "cart___zh_hk"
  }, {
    path: "/zh_hk/checkout",
    component: _327fd472,
    name: "checkout___zh_hk",
    children: [{
      path: "error-page",
      component: _23759d8c,
      name: "error-page___zh_hk"
    }, {
      path: "payment",
      component: _3f241dc9,
      name: "payment___zh_hk"
    }, {
      path: "shipping",
      component: _a4423b0a,
      name: "shipping___zh_hk"
    }, {
      path: "shipping-address",
      component: _46609729,
      name: "shipping-address___zh_hk"
    }, {
      path: "thank-you",
      component: _d126a3bc,
      name: "thank-you___zh_hk"
    }, {
      path: "user-account",
      component: _9f4607b6,
      name: "user-account___zh_hk"
    }]
  }, {
    path: "/zh_hk/confirm-subscription",
    component: _0710fa5c,
    name: "confirm-subscription___zh_hk"
  }, {
    path: "/zh_hk/contact",
    component: _5de44616,
    name: "contact___zh_hk"
  }, {
    path: "/zh_hk/customer",
    component: _7ff18d50,
    meta: {"titleLabel":"Account | Diptyque Paris"},
    name: "customer___zh_hk",
    children: [{
      path: "addresses-details",
      component: _5eddd8c6,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-addresses-details___zh_hk"
    }, {
      path: "my-newsletter",
      component: _581a9ade,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-my-newsletter___zh_hk"
    }, {
      path: "my-profile",
      component: _766757c2,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-my-profile___zh_hk"
    }, {
      path: "my-reviews",
      component: _42fe0774,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-my-reviews___zh_hk"
    }, {
      path: "my-vault-card",
      component: _57a2fb97,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-vault-card-list___zh_hk"
    }, {
      path: "my-wishlist",
      component: _1e58f5ac,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-my-wishlist___zh_hk"
    }, {
      path: "order-history",
      component: _106f15f4,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-order-history___zh_hk"
    }, {
      path: "addresses-details/create",
      component: _7431aa12,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      name: "customer-addresses-details-new___zh_hk"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _2208a908,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      props: true,
      name: "customer-addresses-details-edit___zh_hk"
    }, {
      path: "order-history/:orderId",
      component: _648bbe2d,
      meta: {"titleLabel":"Account | Diptyque Paris"},
      props: true,
      name: "customer-single-order___zh_hk"
    }]
  }, {
    path: "/zh_hk/Home",
    component: _3918f4fa,
    name: "Home___zh_hk"
  }, {
    path: "/zh_hk/login",
    component: _047e46fe,
    meta: {"titleLabel":"Login"},
    name: "customer-login___zh_hk"
  }, {
    path: "/zh_hk/Page",
    component: _33a89b33,
    name: "Page___zh_hk"
  }, {
    path: "/zh_hk/reset-password",
    component: _52e961c2,
    name: "reset-password___zh_hk"
  }, {
    path: "/de_eu/c/:slug+.html",
    component: _2e5b108a,
    name: "c-page___de_eu"
  }, {
    path: "/de_eu/category-preview/:slug_1?",
    component: _65320980,
    name: "category-preview___de_eu"
  }, {
    path: "/de_eu/page/:slug+",
    component: _2e5b108a,
    name: "page___de_eu"
  }, {
    path: "/de_eu/product-preview/:slug_1?",
    component: _d7e18fc6,
    name: "product-preview___de_eu"
  }, {
    path: "/de_eu/search/:query?",
    component: _17a7d42a,
    name: "vaimoKlevuSearch-route___de_eu"
  }, {
    path: "/en_eu/c/:slug+.html",
    component: _2e5b108a,
    name: "c-page___en_eu"
  }, {
    path: "/en_eu/category-preview/:slug_1?",
    component: _65320980,
    name: "category-preview___en_eu"
  }, {
    path: "/en_eu/page/:slug+",
    component: _2e5b108a,
    name: "page___en_eu"
  }, {
    path: "/en_eu/product-preview/:slug_1?",
    component: _d7e18fc6,
    name: "product-preview___en_eu"
  }, {
    path: "/en_eu/search/:query?",
    component: _17a7d42a,
    name: "vaimoKlevuSearch-route___en_eu"
  }, {
    path: "/en_hk/c/:slug+.html",
    component: _2e5b108a,
    name: "c-page___en_hk"
  }, {
    path: "/en_hk/category-preview/:slug_1?",
    component: _65320980,
    name: "category-preview___en_hk"
  }, {
    path: "/en_hk/page/:slug+",
    component: _2e5b108a,
    name: "page___en_hk"
  }, {
    path: "/en_hk/product-preview/:slug_1?",
    component: _d7e18fc6,
    name: "product-preview___en_hk"
  }, {
    path: "/en_hk/search/:query?",
    component: _17a7d42a,
    name: "vaimoKlevuSearch-route___en_hk"
  }, {
    path: "/en_uk/c/:slug+.html",
    component: _2e5b108a,
    name: "c-page___en_uk"
  }, {
    path: "/en_uk/category-preview/:slug_1?",
    component: _65320980,
    name: "category-preview___en_uk"
  }, {
    path: "/en_uk/page/:slug+",
    component: _2e5b108a,
    name: "page___en_uk"
  }, {
    path: "/en_uk/product-preview/:slug_1?",
    component: _d7e18fc6,
    name: "product-preview___en_uk"
  }, {
    path: "/en_uk/search/:query?",
    component: _17a7d42a,
    name: "vaimoKlevuSearch-route___en_uk"
  }, {
    path: "/en_us/c/:slug+.html",
    component: _2e5b108a,
    name: "c-page___en_us"
  }, {
    path: "/en_us/c/:slug+.html",
    component: _2e5b108a,
    name: "c-page___en_us"
  }, {
    path: "/en_us/category-preview/:slug_1?",
    component: _65320980,
    name: "category-preview___en_us"
  }, {
    path: "/en_us/category-preview/:slug_1?",
    component: _65320980,
    name: "category-preview___en_us"
  }, {
    path: "/en_us/page/:slug+",
    component: _2e5b108a,
    name: "page___en_us"
  }, {
    path: "/en_us/page/:slug+",
    component: _2e5b108a,
    name: "page___en_us"
  }, {
    path: "/en_us/product-preview/:slug_1?",
    component: _d7e18fc6,
    name: "product-preview___en_us"
  }, {
    path: "/en_us/product-preview/:slug_1?",
    component: _d7e18fc6,
    name: "product-preview___en_us"
  }, {
    path: "/en_us/search/:query?",
    component: _17a7d42a,
    name: "vaimoKlevuSearch-route___en_us"
  }, {
    path: "/en_us/search/:query?",
    component: _17a7d42a,
    name: "vaimoKlevuSearch-route___en_us"
  }, {
    path: "/es_eu/c/:slug+.html",
    component: _2e5b108a,
    name: "c-page___es_eu"
  }, {
    path: "/es_eu/category-preview/:slug_1?",
    component: _65320980,
    name: "category-preview___es_eu"
  }, {
    path: "/es_eu/page/:slug+",
    component: _2e5b108a,
    name: "page___es_eu"
  }, {
    path: "/es_eu/product-preview/:slug_1?",
    component: _d7e18fc6,
    name: "product-preview___es_eu"
  }, {
    path: "/es_eu/search/:query?",
    component: _17a7d42a,
    name: "vaimoKlevuSearch-route___es_eu"
  }, {
    path: "/fr_eu/c/:slug+.html",
    component: _2e5b108a,
    name: "c-page___fr_eu"
  }, {
    path: "/fr_eu/category-preview/:slug_1?",
    component: _65320980,
    name: "category-preview___fr_eu"
  }, {
    path: "/fr_eu/page/:slug+",
    component: _2e5b108a,
    name: "page___fr_eu"
  }, {
    path: "/fr_eu/product-preview/:slug_1?",
    component: _d7e18fc6,
    name: "product-preview___fr_eu"
  }, {
    path: "/fr_eu/search/:query?",
    component: _17a7d42a,
    name: "vaimoKlevuSearch-route___fr_eu"
  }, {
    path: "/fr_fr/c/:slug+.html",
    component: _2e5b108a,
    name: "c-page___fr_fr"
  }, {
    path: "/fr_fr/category-preview/:slug_1?",
    component: _65320980,
    name: "category-preview___fr_fr"
  }, {
    path: "/fr_fr/page/:slug+",
    component: _2e5b108a,
    name: "page___fr_fr"
  }, {
    path: "/fr_fr/product-preview/:slug_1?",
    component: _d7e18fc6,
    name: "product-preview___fr_fr"
  }, {
    path: "/fr_fr/search/:query?",
    component: _17a7d42a,
    name: "vaimoKlevuSearch-route___fr_fr"
  }, {
    path: "/fr_us/c/:slug+.html",
    component: _2e5b108a,
    name: "c-page___fr_us"
  }, {
    path: "/fr_us/c/:slug+.html",
    component: _2e5b108a,
    name: "c-page___fr_us"
  }, {
    path: "/fr_us/category-preview/:slug_1?",
    component: _65320980,
    name: "category-preview___fr_us"
  }, {
    path: "/fr_us/category-preview/:slug_1?",
    component: _65320980,
    name: "category-preview___fr_us"
  }, {
    path: "/fr_us/page/:slug+",
    component: _2e5b108a,
    name: "page___fr_us"
  }, {
    path: "/fr_us/page/:slug+",
    component: _2e5b108a,
    name: "page___fr_us"
  }, {
    path: "/fr_us/product-preview/:slug_1?",
    component: _d7e18fc6,
    name: "product-preview___fr_us"
  }, {
    path: "/fr_us/product-preview/:slug_1?",
    component: _d7e18fc6,
    name: "product-preview___fr_us"
  }, {
    path: "/fr_us/search/:query?",
    component: _17a7d42a,
    name: "vaimoKlevuSearch-route___fr_us"
  }, {
    path: "/fr_us/search/:query?",
    component: _17a7d42a,
    name: "vaimoKlevuSearch-route___fr_us"
  }, {
    path: "/it_eu/c/:slug+.html",
    component: _2e5b108a,
    name: "c-page___it_eu"
  }, {
    path: "/it_eu/category-preview/:slug_1?",
    component: _65320980,
    name: "category-preview___it_eu"
  }, {
    path: "/it_eu/page/:slug+",
    component: _2e5b108a,
    name: "page___it_eu"
  }, {
    path: "/it_eu/product-preview/:slug_1?",
    component: _d7e18fc6,
    name: "product-preview___it_eu"
  }, {
    path: "/it_eu/search/:query?",
    component: _17a7d42a,
    name: "vaimoKlevuSearch-route___it_eu"
  }, {
    path: "/ja_jp/c/:slug+.html",
    component: _2e5b108a,
    name: "c-page___ja_jp"
  }, {
    path: "/ja_jp/category-preview/:slug_1?",
    component: _65320980,
    name: "category-preview___ja_jp"
  }, {
    path: "/ja_jp/page/:slug+",
    component: _2e5b108a,
    name: "page___ja_jp"
  }, {
    path: "/ja_jp/product-preview/:slug_1?",
    component: _d7e18fc6,
    name: "product-preview___ja_jp"
  }, {
    path: "/ja_jp/search/:query?",
    component: _17a7d42a,
    name: "vaimoKlevuSearch-route___ja_jp"
  }, {
    path: "/zh_hk/c/:slug+.html",
    component: _2e5b108a,
    name: "c-page___zh_hk"
  }, {
    path: "/zh_hk/category-preview/:slug_1?",
    component: _65320980,
    name: "category-preview___zh_hk"
  }, {
    path: "/zh_hk/page/:slug+",
    component: _2e5b108a,
    name: "page___zh_hk"
  }, {
    path: "/zh_hk/product-preview/:slug_1?",
    component: _d7e18fc6,
    name: "product-preview___zh_hk"
  }, {
    path: "/zh_hk/search/:query?",
    component: _17a7d42a,
    name: "vaimoKlevuSearch-route___zh_hk"
  }, {
    path: "/de_eu/l/:slug_1/:slug_2?/:slug_3?/:slug_4?/:slug_5?",
    component: _65320980,
    name: "category___de_eu"
  }, {
    path: "/en_eu/l/:slug_1/:slug_2?/:slug_3?/:slug_4?/:slug_5?",
    component: _65320980,
    name: "category___en_eu"
  }, {
    path: "/en_hk/l/:slug_1/:slug_2?/:slug_3?/:slug_4?/:slug_5?",
    component: _65320980,
    name: "category___en_hk"
  }, {
    path: "/en_uk/l/:slug_1/:slug_2?/:slug_3?/:slug_4?/:slug_5?",
    component: _65320980,
    name: "category___en_uk"
  }, {
    path: "/en_us/l/:slug_1/:slug_2?/:slug_3?/:slug_4?/:slug_5?",
    component: _65320980,
    name: "category___en_us"
  }, {
    path: "/en_us/l/:slug_1/:slug_2?/:slug_3?/:slug_4?/:slug_5?",
    component: _65320980,
    name: "category___en_us"
  }, {
    path: "/es_eu/l/:slug_1/:slug_2?/:slug_3?/:slug_4?/:slug_5?",
    component: _65320980,
    name: "category___es_eu"
  }, {
    path: "/fr_eu/l/:slug_1/:slug_2?/:slug_3?/:slug_4?/:slug_5?",
    component: _65320980,
    name: "category___fr_eu"
  }, {
    path: "/fr_fr/l/:slug_1/:slug_2?/:slug_3?/:slug_4?/:slug_5?",
    component: _65320980,
    name: "category___fr_fr"
  }, {
    path: "/fr_us/l/:slug_1/:slug_2?/:slug_3?/:slug_4?/:slug_5?",
    component: _65320980,
    name: "category___fr_us"
  }, {
    path: "/fr_us/l/:slug_1/:slug_2?/:slug_3?/:slug_4?/:slug_5?",
    component: _65320980,
    name: "category___fr_us"
  }, {
    path: "/it_eu/l/:slug_1/:slug_2?/:slug_3?/:slug_4?/:slug_5?",
    component: _65320980,
    name: "category___it_eu"
  }, {
    path: "/ja_jp/l/:slug_1/:slug_2?/:slug_3?/:slug_4?/:slug_5?",
    component: _65320980,
    name: "category___ja_jp"
  }, {
    path: "/zh_hk/l/:slug_1/:slug_2?/:slug_3?/:slug_4?/:slug_5?",
    component: _65320980,
    name: "category___zh_hk"
  }, {
    path: "/de_eu/p/:slug/:slug_2?/:slug_3?/:slug_4?/:slug_5?/:slug_6?/:slug_7?",
    component: _d7e18fc6,
    name: "product___de_eu"
  }, {
    path: "/en_eu/p/:slug/:slug_2?/:slug_3?/:slug_4?/:slug_5?/:slug_6?/:slug_7?",
    component: _d7e18fc6,
    name: "product___en_eu"
  }, {
    path: "/en_hk/p/:slug/:slug_2?/:slug_3?/:slug_4?/:slug_5?/:slug_6?/:slug_7?",
    component: _d7e18fc6,
    name: "product___en_hk"
  }, {
    path: "/en_uk/p/:slug/:slug_2?/:slug_3?/:slug_4?/:slug_5?/:slug_6?/:slug_7?",
    component: _d7e18fc6,
    name: "product___en_uk"
  }, {
    path: "/en_us/p/:slug/:slug_2?/:slug_3?/:slug_4?/:slug_5?/:slug_6?/:slug_7?",
    component: _d7e18fc6,
    name: "product___en_us"
  }, {
    path: "/en_us/p/:slug/:slug_2?/:slug_3?/:slug_4?/:slug_5?/:slug_6?/:slug_7?",
    component: _d7e18fc6,
    name: "product___en_us"
  }, {
    path: "/es_eu/p/:slug/:slug_2?/:slug_3?/:slug_4?/:slug_5?/:slug_6?/:slug_7?",
    component: _d7e18fc6,
    name: "product___es_eu"
  }, {
    path: "/fr_eu/p/:slug/:slug_2?/:slug_3?/:slug_4?/:slug_5?/:slug_6?/:slug_7?",
    component: _d7e18fc6,
    name: "product___fr_eu"
  }, {
    path: "/fr_fr/p/:slug/:slug_2?/:slug_3?/:slug_4?/:slug_5?/:slug_6?/:slug_7?",
    component: _d7e18fc6,
    name: "product___fr_fr"
  }, {
    path: "/fr_us/p/:slug/:slug_2?/:slug_3?/:slug_4?/:slug_5?/:slug_6?/:slug_7?",
    component: _d7e18fc6,
    name: "product___fr_us"
  }, {
    path: "/fr_us/p/:slug/:slug_2?/:slug_3?/:slug_4?/:slug_5?/:slug_6?/:slug_7?",
    component: _d7e18fc6,
    name: "product___fr_us"
  }, {
    path: "/it_eu/p/:slug/:slug_2?/:slug_3?/:slug_4?/:slug_5?/:slug_6?/:slug_7?",
    component: _d7e18fc6,
    name: "product___it_eu"
  }, {
    path: "/ja_jp/p/:slug/:slug_2?/:slug_3?/:slug_4?/:slug_5?/:slug_6?/:slug_7?",
    component: _d7e18fc6,
    name: "product___ja_jp"
  }, {
    path: "/zh_hk/p/:slug/:slug_2?/:slug_3?/:slug_4?/:slug_5?/:slug_6?/:slug_7?",
    component: _d7e18fc6,
    name: "product___zh_hk"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
