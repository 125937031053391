import { computed } from '@nuxtjs/composition-api';

import { useLink, useScreenSize } from '~/diptyqueTheme/composable';

export function useDeclination(sectionData) {
  const { isDesktop } = useScreenSize();
  const { normalizeLink } = useLink();
  const checkForDesktop = computed<boolean>(() => isDesktop.value);

  const mobileDeclinationOption = computed(() => {
    let option = 1;
    if (sectionData.mVar) option = sectionData.mVar.substring(7, 8);
    return option;
  });

  const desktopDeclinationOption = computed(() => {
    let option = 1;
    if (sectionData.dVar) option = sectionData.dVar.substring(7, 8);
    return option;
  });

  // TODO: Solve the issue when mobile component comes from server while SSR even for desktop
  const getDeclinationComponent = computed(() => {
    const mobile = 'MobileDeclination' + mobileDeclinationOption.value;
    const desktop = 'DesktopDeclination' + desktopDeclinationOption.value;
    return checkForDesktop.value ? desktop : mobile;
  });

  const getDeclinationClass = computed(() => {
    const mobile = 'mobile-declination-' + mobileDeclinationOption.value;
    const desktop = 'desktop-declination-' + desktopDeclinationOption.value;
    return checkForDesktop.value ? desktop : mobile;
  });

  const getBannerData = computed(() => {
    return getBannerFunction(sectionData);
  });

  const getVisualData = computed(() => {
    return getVisualFunction(sectionData);
  });

  const getBannerFunction = (sectionData) => {
    return {
      source: 'contentful',
      src: sectionData?.visual?.url,
      type: sectionData?.visual?.contentType?.split('/')[0],
      srcDesktop: sectionData?.visualDesktop?.url,
      typeDesktop: sectionData?.visualDesktop?.contentType?.split('/')[0],
      link: normalizeLink(sectionData?.visualLink),
      title: sectionData?.heading,
      autoplay: sectionData?.vSettings?.includes('Autoplay'),
      muted: sectionData?.vSettings?.includes('Muted'),
      loop: sectionData?.vSettings?.includes('Loop'),
      srcAdaptive: checkForDesktop.value && sectionData?.visualDesktop?.url ? sectionData?.visualDesktop?.url : sectionData?.visual?.url,
      controlsColor: sectionData?.controlsColor || 'white',
      visible: !!sectionData?.visual?.url || !!(checkForDesktop.value && sectionData?.visualDesktop?.url),
      typeName: sectionData?.__typename
    };
  };

  const getVisualFunction = (sectionData) => {
    const visual = sectionData?.visualEntry || sectionData?.visual;
    if (!visual) {
      return {
        visible: false
      };
    }

    const vis = visual.vis?.url;
    const visD = visual.visD?.url;

    return {
      source: 'contentful',
      src: vis,
      type: visual.vis?.contentType?.split('/')[0],
      srcDesktop: visD,
      typeDesktop: visual.visD?.contentType?.split('/')[0],
      link: normalizeLink(visual.link),
      title: sectionData.heading,
      autoplay: visual.vidSet?.includes('Autoplay'),
      muted: visual.vidSet?.includes('Muted'),
      loop: visual.vidSet?.includes('Loop'),
      srcAdaptive: checkForDesktop.value && visD ? visD : vis,
      controlsColor: visual.ctrlCol || 'white',
      ratio: checkForDesktop.value ? visual.ratioD : visual.ratio,
      visible: !!vis || !!(checkForDesktop.value && visD),
      labels: visual.labels || [],
      position: visual.posD
    };
  };

  const getBannerOrVisualData = computed(() => {
    const visual = sectionData?.visualEntry || sectionData?.visual;
    if (visual?.__typename?.toLowerCase() === 'visual') {
      return getVisualData.value;
    }
    return getBannerData.value;
  });

  const getCTAdata = computed(() => {
    let variant = 'default';
    if (sectionData.ctaType === 'Transactional CTA Button') variant = 'primary';
    const link = normalizeLink(sectionData.ctaLink);
    const hasLinkAndText = !!(link && sectionData.ctaText);

    return {
      link: link,
      text: sectionData.ctaText,
      buttonVisibility: hasLinkAndText && sectionData.ctaType?.toLowerCase() !== 'hidden',
      hasLinkAndText,
      variant
    };
  });

  const getCTALinkData = computed(() => {
    const link = normalizeLink(sectionData.ctaLinkLink);
    const hasLinkAndText = !!(link && sectionData.ctaLinkText);

    return {
      link: link,
      text: sectionData.ctaLinkText,
      buttonVisibility: hasLinkAndText,
      hasLinkAndText,
      variant: 'link'
    };
  });

  const getButtonDataFunction = (buttonEntry) => {
    if (buttonEntry?.__typename?.toLowerCase() !== 'button') return {};

    const variantsMap = {
      'Transactional CTA': 'primary',
      'CTA Button': 'default',
      'CTA External': 'default',
      'CTA Link': 'link',
      'CTA Edito Link': 'edito link',
      'CTA Email': 'default'
    };

    const getType = () => (checkForDesktop.value && buttonEntry.desktopType ? buttonEntry.desktopType : buttonEntry.type);

    const getLayerContent = (btn) => {
      return btn?.layerCont;
    };

    const type = getType();
    const variant = type && variantsMap[type] ? variantsMap[type] : 'default';
    const desktopType = checkForDesktop.value && buttonEntry.desktopType ? variant : '';

    const link = normalizeLink(buttonEntry?.link || '');
    const buttonText = buttonEntry.text || '';

    const isButtonVisible = !!(link && buttonText) || type === 'CTA Email' || !!buttonEntry.layerCont;
    const isButtonExternal = type === 'CTA External' || buttonEntry.blank;
    const linkBehavior = isButtonExternal ? 'open in new tab' : null;

    const icon = type === 'CTA External' ? 'arrowtopright' : null;
    const layerContent = getLayerContent(buttonEntry);

    const isEmail = type === 'CTA Email';
    const emailButtonText = isEmail ? buttonEntry.emailButtonText : null;
    const isBooxiButton = buttonEntry.isBooxiButton;
    const isCalendarButton = buttonEntry.isCalendarButton;

    const disabled = buttonEntry.disabled ?? false;
    const preventDefault = buttonEntry.preventDefault ?? false;
    const position = buttonEntry.position || 'Default';
    const eventTitle = buttonEntry.eventTitle;
    const eventDescription = buttonEntry.eventDescr;

    return {
      desktopType,
      variant,
      link,
      buttonText,
      isButtonVisible,
      isButtonExternal,
      linkBehavior,
      icon,
      layerContent,
      isEmail,
      emailButtonText,
      isBooxiButton,
      isCalendarButton,
      disabled,
      preventDefault,
      position,
      eventTitle,
      eventDescription
    };
  };

  const getButtonData = computed(() => {
    if (sectionData.button?.items?.length) {
      return sectionData.button.items.map(getButtonDataFunction);
    }

    return getButtonDataFunction(sectionData.button);
  });

  return {
    getButtonData,
    getButtonDataFunction,
    getDeclinationComponent,
    getVisualData,
    getVisualFunction,
    getBannerFunction,
    getBannerOrVisualData,
    getDeclinationClass,
    getBannerData,
    getCTALinkData,
    getCTAdata,
    isDesktop: checkForDesktop
  };
}
